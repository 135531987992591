<template>
  <div>
    <h5 class="font-weight-semibold">
      {{ $t("payment.vault.title") }}
    </h5>
    <p class="vault-description" v-html="$t('payment.vault.desc')"></p>
    <div
      class="d-flex align-center grey lighten-2 rounded-md align-center px-3 mb-3"
    >
      <v-icon color="primary" size="32" class="mr-6 ml-2">
        $vault
      </v-icon>
      <div>
        <div class="mt-2">{{ $t("payment.vault.innerTitle") }}</div>
        <div v-if="typeof moneyCheckout === 'number'" class="text-h3 mt-n2">
          {{ $n(moneyCheckout, "currency") }}
        </div>
        <div v-else class="text-h3 mt-n2">{{ $n(0, "currency") }}</div>
      </div>
    </div>

    <v-form @submit.prevent="addMoneyCheckout" ref="vaultForm">
      <v-row v-if="moneyCheckout >= 1" class="py-2">
        <v-col cols="12">
          <span class="d-block font-weight-semibold">{{
            $t("payment.vault.disclaimer")
          }}</span>
        </v-col>
        <v-col cols="9">
          <v-text-field
            class="rounded-pill"
            v-model="vaultAmount"
            :label="$t('payment.vault.form.amount')"
            :placeholder="$t('payment.vault.form.amount')"
            height="36"
            outlined
            dense
            :rules="vaultRules"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn
            v-if="vaultUsed"
            depressed
            outlined
            class="rounded-pill d-block w-100"
            height="40"
            color="primary"
            @click="removeMoneyCheckout()"
          >
            {{ $t("payment.vault.form.btnDel") }}
          </v-btn>
          <v-btn
            v-else
            depressed
            class="rounded-pill d-block w-100"
            height="40"
            color="primary"
            type="submit"
          >
            {{ $t("payment.vault.form.btnAdd") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-divider class="my-4"></v-divider>
  </div>
</template>
<style lang="scss">
.payment-type-list-container {
  .text--darken-2.text-h3 {
    font-size: 18px !important;
  }
}
.vault-description {
  font-size: 14px;
}
</style>
<script>
import { isInteger } from "~/validator/validationRules";
import pointsMixins from "@/mixins/pointsMixins";
import { mapActions } from "vuex";

export default {
  name: "BonusCassaforte",
  mixins: [pointsMixins],

  data() {
    return {
      vaultAmount: null,
      vaultRules: [isInteger, value => this.maxAmount(value)],
      vaultUsed: false
    };
  },

  methods: {
    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    }),
    async addMoneyCheckout() {
      if (
        this.vaultAmount &&
        Number(this.vaultAmount) > 0 &&
        Number(this.vaultAmount) <= this.moneyCheckout
      ) {
        if (this.$refs.vaultForm.validate()) {
          await this.setCartInfo({ VAULT_VALUE: this.vaultAmount });
          this.vaultUsed = true;
        }
      }
    },
    async removeMoneyCheckout() {
      await this.setCartInfo({ VAULT_VALUE: null });
      this.vaultUsed = false;
      this.vaultAmount = null;
    }
  },
  computed: {
    maxAmount() {
      return value =>
        (Number(value) <= this.moneyCheckout &&
          Number(value) > 0 &&
          Number(value) % 1 === 0) ||
        this.$t("payment.vault.form.validationErr", [this.moneyCheckout]);
    }
  },
  watch: {
    address1(val, oldVal) {
      if (val && val.trim().length > 3 && val != oldVal) {
        this.fetchAddressSuggestion(val);
      }
    },
    city(val, oldVal) {
      if (val && val.trim().length > 0 && val != oldVal) {
        this.fetchCityList(val, this.provinceId);
      }
    }
  },
  async created() {
    let vm = this;
    if (vm.$route.params.addressId) {
      this.showAutomplete = false;
      this.homeDeliveryAvailable = true;
      await vm.getAddress(vm.$route.params.addressId);
    }
    if (this.cart.cartInfos?.VAULT_VALUE) {
      this.vaultUsed = true;
      this.vaultAmount = this.cart.cartInfos.VAULT_VALUE;
    }
    // vm.fetchDefaultData();
  },
  mounted() {
    this.resetForm();
  }
};
</script>
