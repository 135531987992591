<template>
  <div class="payment-container">
    <v-container class="payment">
      <category-block
        :target="category"
        position="position1"
        class="category-block category-block-1"
      />
      <div class="checkout-title">
        <CategoryTitle
          :category="category"
          :showOnMobile="true"
          :centered="$vuetify.breakpoint.xs"
        />
      </div>
      <v-row>
        <v-col cols="12" md="4" lg="4" order="1" order-md="2" class="pt-0">
          <CartInfoAddressCard
            class="w-100 cart-info-address-card"
            :shippingAddress="cart.shippingAddress"
            :editable="false"
          />
          <CartInfoTimeslotCard
            class="w-100 cart-info-timeslot-card"
            :shippingAddress="cart.shippingAddress"
            :timeslot="cart.timeslot"
            :editable="false"
            :value="timeslot"
          />
          <CheckoutSummary
            class="white mb-6 mx-0 rounded-md"
            :orderCart="cart"
            :showEarnedCredit="true"
            :canDeleteGift="false"
          />
        </v-col>
        <v-col
          cols="12"
          md="8"
          lg="8"
          class="pa-4 white rounded-md mb-7"
          order="2"
          order-md="1"
        >
          <!-- <PortInformations
            v-if="cart.shippingAddress.addressTypeId == 6"
            @portInfoCompleted="portInfoFormCompleted"/> -->
          <div
            class="other-informations lighten-1 pa-4 mt-6 rounded-lg"
            v-if="cart.shippingAddress.addressTypeId == 2"
          >
            <h3 class="my-1 text-uppercase">
              {{ $t("paymentInvoice.additionalInfo") }}
            </h3>
            <!-- Notes -->
            <label v-html="`${$t('paymentInvoice.notes')}`" />
            <v-text-field
              :value="cart.cartInfos.notes"
              :placeholder="`${$t('paymentInvoice.notes')}`"
              hide-details="auto"
              maxlength="255"
              solo
              @blur="processOnboardChange('notes', $event.target.value)"
            />
          </div>
          <!-- <CollezionamentoSlider
            :timeslotId="cart.timeslot.date + cart.timeslot.timeslotId"
            :userId="cart.user.userId"
            :warehouseId="cart.warehouse.warehouseId"
            :layout="36"
            :limit="24"
            :lg="3"
            :xl="4"
            :paginationClass="'swiper-pagination-linea-220'"
            :showArrows="!$vuetify.breakpoint.xsOnly"
            :showBullets="$vuetify.breakpoint.xsOnly"
          >
            <h2 class="my-3 font-weight-regular text-uppercase">
              {{ $t("product.rewards") }}
            </h2>
          </CollezionamentoSlider> -->
          <div class="mb-3 px-3 px-sm-0">
            <template v-if="cart.user.betaTester == '1'">
              <PaymentInvoice
                @billingFormCompleted="handleInvoiceFormValidation"
                @invoiceFormValidation="handleInvoiceFormValidation"
              />
              <v-divider class="mt-3 mb-8"></v-divider>
            </template>
            <PackagesTypeList
              :showTermal="
                cart.shippingAddress.deliveryServiceId != 2 &&
                  !(
                    cart.warehouse.metaData &&
                    cart.warehouse.metaData.warehouse_info &&
                    cart.warehouse.metaData.warehouse_info.HIDE_SPECIAL_PACKAGES
                  )
              "
            />
            <v-divider class="my-4"></v-divider>
            <PaymentShippingInfo
              v-if="cart.shippingAddress.deliveryServiceId === 2"
              @shippingFormValidation="handleShippingFormValidation"
            ></PaymentShippingInfo>
            <BonusCassaforte
              v-if="fidelityCardType === 'CARTA_VANTAGGI_PIU'"
              @vaultFormValidation="handleVaultFormValidation"
            ></BonusCassaforte>
            <h5 class="font-weight-semibold mt-4 mb-3">
              {{ $t("payment.sellConditions.title") }}
            </h5>
            <v-checkbox
              class="mt-2 conditions"
              hide-details
              required
              v-model="sellCondition"
            >
              <template v-slot:label>
                <div v-html="$t('payment.sellConditions.label')"></div>
              </template>
            </v-checkbox>
            <PaymentTypeList
              class="ptl-wrapper py-4"
              :options="{
                showRememberCard: true,
                rememberCardDefaultValue: true,
                showPaymentMethodImage: false,
                showCardLogos: true,
                showPrebilledAmountInfoText: true,
                mainBackgroundColor: 'transparent',
                confirm: {
                  show: true,
                  class: 'rounded-pill white--text'
                },
                back: {
                  outline: true,
                  class: 'primary--text rounded-pill payment-back-btn'
                },
                titleStyle: 'default--text payment-title mb-2 text-uppercase'
              }"
              :isPaymentDisabled="isPaymentDisabled"
              :deliveryServiceId="cart.shippingAddress.deliveryServiceId"
              :paymentNameToPaymentInfo="true"
              :showAddNewCardWarningInfo="false"
            /></div
        ></v-col>
      </v-row>
      <category-block
        :target="category"
        position="position2"
        class="category-block category-block-2"
      />

      <v-btn
        class="standard-back-btn rounded-pill"
        color="primary"
        depressed
        large
        :to="{ name: 'Checkout' }"
      >
        {{ $t("payment.backBtn") }}
      </v-btn>
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.ptl-wrapper {
  :deep(.primary--text) {
    color: #464646 !important;
  }
  :deep(.payment-title) {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  :deep(.prebilled-amount) {
    font-weight: normal;
    line-height: 1.2;
    font-size: 12px !important;
    .v-alert__content div {
      font-size: 12px !important;
    }
  }
  :deep(.payment-type) {
    .payment-back-btn {
      border: 1px solid var(--v-primary-base);
      color: var(--v-primary-base) !important;
    }
    .v-btn {
      border-radius: 9999px !important;
    }
  }
  :deep(.add-new-card-btn) {
    box-shadow: none;
    margin-right: 12px;
    height: 30px !important;
    min-width: 190px !important;
    padding: 0 16px;
  }
  :deep(.prebilled-amount) {
    margin-bottom: 0px !important;
  }
  :deep(.actions-btn-container) {
    display: flex !important;
    justify-content: space-between !important;
    padding: 0 8px !important;
  }
  :deep(.go-back-btn) {
    height: 44px !important;
    min-width: 78px !important;
    padding: 0 19.5555555556px !important;
    color: var(--v-primary-base) !important;
    border: 1px solid var(--v-primary-base) !important;
  }
  :deep(.gateway-btn) {
    height: 44px !important;
    min-width: 78px !important;
    padding: 0 19.5555555556px !important;
    color: #ffffff !important;
  }
  :deep(.add-card-container) {
    flex-direction: column-reverse !important;
    align-items: flex-start !important;
  }
  :deep(.remember-card-checkbox) {
    margin-bottom: 12px;
    .v-label {
      font-size: 13px !important;
      font-weight: 400 !important;
    }
    .v-icon {
      font-size: 20px !important;
    }
  }
}
.payment {
  .cart-info-timeslot-card,
  .cart-info-address-card {
    pointer-events: none;
  }
  .v-stepper {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      max-width: 80%;
    }
  }
  .cart-info-timeslot-card {
    .value {
      text-transform: uppercase;
    }
  }
  .categoryDescription {
    .content {
      background-color: $white;
    }
    :deep(h2) {
      font-size: 14px;
      background-color: var(--v-default-lighten1);
      text-transform: uppercase;
      padding: 10px;
      line-height: 1.5;
    }
    :deep(p) {
      padding: 10px;
      font-size: 14px;
      color: var(--v-default-base);
    }
  }

  .payment-methods {
    border-radius: 8px !important;
    :deep(p) {
      margin-bottom: 0px;
    }
    :deep(.text-h3) {
      font-weight: 600;
      font-size: 24px !important;
      text-transform: uppercase;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      :deep(.text-h3) {
        font-size: 18px !important;
      }
    }
    :deep(.v-expansion-panel) {
      margin: 8px 0 !important;
      padding: 0 8px !important;
      border: 2px solid var(--v-grey-lighten1);
      padding: 0;
      border-radius: $border-radius-root;
      margin-bottom: 0px;
      &.v-expansion-panel--active {
        border-radius: $border-radius-root;
      }
      &:last-child {
        margin-bottom: 0px;
        // border-top: none;
      }
      &::before {
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
      }
      .v-expansion-panel-header {
        border-radius: $border-radius-root !important;
        padding-left: 0px;
        label {
          display: flex;
          align-items: center;
          // padding-left: 20px;
        }
        .payment-method-img {
          height: 50px !important;
        }
      }
      .v-expansion-panel-content__wrap {
        padding: 12px 24px !important;
        border-radius: $border-radius-root !important;

        .prebilled-amount {
          margin-bottom: 20px;
        }
        .caption {
          margin-top: 20px;
        }
      }

      .prebilledAmountInfo {
        margin-bottom: 20px;
        .text {
          color: var(--v-primary-lighten1);
        }
        .sub {
          font-size: 13px;
        }
      }
      label {
        text-transform: none;
        font-weight: 600;
      }
      label:before {
        // top: 15px;
        background: transparent;
      }
      label:after {
        // top: 20px;
      }
    }
    :deep(.v-expansion-panel::after) {
      display: none;
    }
  }
  .other-informations {
    h3 {
      font-weight: 600;
      font-size: 24px;
    }
    label {
      font-weight: 600;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
    :deep(.v-input__slot) {
      box-shadow: none !important;
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      h3 {
        font-size: 18px;
      }
    }
  }
}
.payment-container {
  .standard-back-btn {
    border: 1px solid var(--v-grey-lighten1) !important;
    background-color: white !important;
    color: var(--v-primary-base);
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";
import PaymentInvoice from "@/components/payment/PaymentInvoice.vue";
import PaymentShippingInfo from "@/components/payment/PaymentShippingInfo.vue";
import BonusCassaforte from "@/components/payment/BonusCassaforte.vue";
// import PortInformations from "@/components/payment/PortInformations.vue";
// import CollezionamentoSlider from "@/components/product/CollezionamentoSlider.vue";
import PackagesTypeList from "@/components/payment/PackagesTypeList.vue";

import AnalyticsService from "~/service/analyticsService";

import productMixins from "~/mixins/product";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";
import pointsMixins from "@/mixins/pointsMixins";

// import { mapCartInfo } from "~/service/ebsn";

import { requiredValue, isEmail } from "~/validator/validationRules";

import { mapActions, mapState, mapGetters } from "vuex";
import { getPersonInfo } from "~/service/ebsn";

export default {
  name: "Checkout",
  mixins: [login, productMixins, cartInfo, categoryMixins, pointsMixins],
  components: {
    CategoryTitle,
    CheckoutSummary,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    PaymentTypeList,
    PaymentInvoice,
    // CollezionamentoSlider,
    PackagesTypeList,
    PaymentShippingInfo,
    BonusCassaforte
    // PortInformations
  },
  data() {
    return {
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      error: null,
      loading: false,
      billingFormCompletedInternal: true,
      portInfoFormCompletedInternal: true,
      emailKey: 1,
      showAlternativesTooltip: false,
      valid: false,
      sellCondition: false,
      invoiceFormValidated: true,
      shippingFormValidated: true,
      vaultFormValidated: true,
      renderKey: 1,
      fidelityCardType: null
    };
  },
  computed: {
    // ...mapCartInfo({ sellCondition: "accept_sell_condition" }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      user: "cart/getUser"
    }),
    isPaymentDisabled() {
      return (
        this.invoiceFormValidated == false ||
        this.shippingFormValidated == false ||
        this.vaultFormValidated == false ||
        this.sellCondition == false
      );
    },
    categoryDescription() {
      return this.$ebsn.meta(this.category, "category_info.DESCRIPTION");
    },
    fullAddress() {
      return (
        this.user.billingAddress.address1 +
        ", " +
        this.user.billingAddress.postalcode +
        " " +
        this.user.billingAddress.city +
        " (" +
        this.user.billingAddress.province +
        ")"
      );
    }
  },

  methods: {
    ...mapActions({
      lockCart: "cart/lockCart",
      setCartInfo: "cart/setCartInfo"
    }),
    portInfoFormCompleted(completed) {
      this.portInfoFormCompletedInternal = completed;
    },
    handleInvoiceFormValidation(value) {
      this.invoiceFormValidated = value;
    },
    handleShippingFormValidation(value) {
      this.shippingFormValidated = value;
    },
    handleVaultFormValidation(value) {
      this.vaultFormValidated = value;
    },
    handleSellConditionValidation(value) {
      this.sellCondition = value;
    },
    processOnboardChange(name, value) {
      this.setCartInfo({ [name]: value });
    },
    async goToPayment() {
      let vm = this;
      vm.loading = true;
      try {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            await vm.lockCart();
            vm.$router.push({
              name: "Payment"
            });
          }
        }
      } catch (err) {
        await this.needTimeslot();
        vm.error = err.message;
      } finally {
        vm.loading = false;
      }
    }
  },
  async mounted() {
    AnalyticsService.beginCheckout(this.cart);
    this.setCartInfo({ need_invoice: false });
    this.fidelityCardType = await getPersonInfo(this.user.person, 100);
  }
};
</script>
