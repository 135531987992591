import { render, staticRenderFns } from "./PackagesTypeList.vue?vue&type=template&id=438ca0db&scoped=true"
import script from "./PackagesTypeList.vue?vue&type=script&lang=js"
export * from "./PackagesTypeList.vue?vue&type=script&lang=js"
import style0 from "./PackagesTypeList.vue?vue&type=style&index=0&id=438ca0db&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "438ca0db",
  null
  
)

export default component.exports