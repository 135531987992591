<template>
  <div v-if="packages && packages.length" class="white pb-4 mt-2 rounded-lg">
    <h5 class="text-uppercase font-weight-semibold">
      {{ $t("checkout.packageAddTitle") }}
    </h5>
    <p class="package-description-label">
      {{ $t("checkout.packageAddDesc") }}
    </p>

    <v-radio-group class="w-100" mandatory v-model="packagingTypeId">
      <v-radio
        v-for="pck in packages"
        :key="pck.productId"
        :value="'' + pck.productId"
        color="primary"
        class="my-4"
        :aria-label="`$t('checkout.packageAddTitle'): ${pck.name}`"
        role="radio"
      >
        <template v-slot:label>
          <div
            class="d-flex align-start align-sm-center flex-column flex-sm-row justify-space-between w-100"
          >
            <div class="d-flex">
              <div class="packaging-descr align-self-center">
                <span class="d-block font-weight-semibold text-body-2">{{
                  pck.name
                }}</span>
                <span class="d-block">{{ pck.description }}</span>
              </div>
            </div>
            <div class="font-weight-semibold ml-auto">{{ pck.shortDescr }}</div>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
    <div v-if="showTermal">
      <p>{{ $t("checkout.packageSpecial") }}</p>
      <v-checkbox
        v-model="packagingThermo"
        color="black"
        hide-details
        true-value="true"
        false-value="false"
        ><template v-slot:label>
          <div
            class="d-flex align-start align-sm-center flex-column flex-sm-row justify-space-between w-100"
          >
            <div class="d-flex">
              <div class="packaging-descr align-self-center">
                <span class="d-block font-weight-semibold text-body-2">{{
                  $t("checkout.packageTermal")
                }}</span>
                <span class="d-block">{{
                  $t("checkout.packageTermalSize")
                }}</span>
              </div>
            </div>
            <div class="font-weight-semibold ml-auto">
              {{ $t("checkout.packageTermalPrice") }}
            </div>
          </div>
        </template></v-checkbox
      >
    </div>
  </div>
</template>
<style scoped lang="scss">
.package-description-label {
  font-size: 14px;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .text-h3 {
    font-size: 18px !important;
  }
}
</style>

<script>
import analyticsService from "@/commons/service/analyticsService";
import cartService from "~/service/cartService";
import { mapCartInfo } from "~/service/ebsn";

export default {
  name: "PackagesTypeList",
  data() {
    return { packages: [] };
  },
  props: {
    showTermal: { type: Boolean, default: true }
  },
  computed: {
    ...mapCartInfo({
      packagingTypeId: "package_type",
      packagingThermo: "package_thermo"
    })
  },
  methods: {
    async fetchPackages() {
      this.packages = await cartService.getPackages();
    }
  },
  mounted() {
    this.fetchPackages();
  },
  watch: {
    packagingTypeId(val, oldVal) {
      if (val !== oldVal) {
        const packageName = this.packages.find(
          packageItem =>
            packageItem.productId.toString() === this.packagingTypeId
        );
        if (packageName && packageName != "") {
          analyticsService.trackEvent("", "package", packageName.name);
        }
      }
    },
    packagingThermo(val, oldVal) {
      if (val !== oldVal) {
        analyticsService.trackEvent(
          "",
          "thermal_packaging",
          JSON.parse(this.packagingThermo)
        );
      }
    }
  }
};
</script>
