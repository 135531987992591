<template>
  <div>
    <h5 class="font-weight-semibold">
      {{ $t("payment.shippingInfo.title") }}
    </h5>
    <v-form ref="shippingAddr" class="shipping-address-form" v-model="valid">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            class="rounded-pill"
            :label="$t('profile.addresses.type')"
            :placeholder="$t('profile.addresses.typePlaceholder')"
            v-model="internalValue.addressName"
            :rules="[requiredRule()]"
            outlined
            required
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            class="rounded-pill"
            :label="$t('profile.addresses.name')"
            :placeholder="$t('profile.addresses.namePlaceholder')"
            v-model="internalValue.doorbellName"
            :rules="[requiredRule()]"
            outlined
            required
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="3">
          <!-- Piano -->
          <v-text-field
            class="rounded-pill"
            :label="$t('profile.addresses.floor')"
            :placeholder="$t('profile.addresses.floorPlaceholder')"
            v-model="internalValue.floor"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <!-- Ascensore -->
        <v-col cols="12" sm="3">
          <v-switch
            v-model="internalValue.elevator"
            :true-value="true"
            :false-value="false"
            inset
            :label="$t('profile.addresses.elevator')"
            dense
            class="mt-2"
            hide-details
          ></v-switch>
        </v-col>

        <v-col cols="12" sm="3">
          <!-- Interno -->
          <v-text-field
            class="rounded-pill"
            :label="$t('profile.addresses.apartmentNumber')"
            :placeholder="$t('profile.addresses.apartmentNumberPlaceholder')"
            v-model="internalValue.apartmentNumber"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="3">
          <v-text-field
            class="rounded-pill"
            :label="$t('profile.addresses.phone')"
            :placeholder="$t('profile.addresses.phonePlaceholder')"
            v-model="internalValue.referencePhoneNumber"
            :rules="isGenericPhoneRules"
            required
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="12">
          <!-- Altre info, addressNote -->
          <v-text-field
            class="rounded-pill"
            :label="$t('profile.addresses.other')"
            :placeholder="$t('profile.addresses.otherPlaceholder')"
            v-model="internalValue.addressNote"
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <!-- <v-col cols="12" sm="6">
      <v-text-field
        class="rounded-pill"
        :label="$t('profile.addresses.doorbellNumber')"
        :placeholder="$t('profile.addresses.doorbellNumberPlaceholder')"
        v-model="internalValue.doorbellNumber"
        outlined
        dense
      >
      </v-text-field>
    </v-col> -->

        <v-col cols="12" class="d-flex justify-center justify-sm-end mb-4">
          <v-btn
            color="primary"
            class="primary-button rounded-pill"
            @click="saveAddress"
            large
            depressed
            :disabled="!checkRequiredValues"
          >
            {{ $t("profile.addresses.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-divider class="my-4"></v-divider>
  </div>
</template>
<style lang="scss"></style>
<script>
import DeliveryService from "~/service/deliveryService";
import { mapActions, mapState } from "vuex";
import { mask } from "vue-the-mask";
import { requiredValue, isGenericPhone } from "~/validator/validationRules";
import storeService from "@/commons/service/storeService";
import merge from "lodash/merge";

export default {
  name: "PaymentShippingInfo",
  props: {
    showBackButton: { type: Boolean, required: false, default: true }
  },
  data() {
    return {
      valid: false,
      // validRequiredValues: false,
      lazy: true,
      error: null,
      showAutomplete: true,
      homeDeliveryAvailable: null,
      googleEnabled: false,
      checkAddressDisabled: true,
      isReadOnly: true,
      isFilled: true,
      internalValue: {
        addressName: null,
        doorbellName: null,
        city: null,
        addressNote: null,
        postalcode: null,
        province: null,
        addressNumber: null,
        address1: null,
        floor: null,
        referencePhoneNumber: null,
        elevator: null,
        longitude: null,
        latitude: null,
        country: null,
        apartmentNumber: null,
        doorbellNumber: null
      },
      address: null,
      provinceList: [],
      cityList: [],
      addressList: [],
      postalcodeList: [],
      requiredRule: requiredValue,
      isGenericPhoneRules: [isGenericPhone()],
      addressGeoCoded: null,
      validateAddress: false,
      missingAddressData: false,
      missingAddressDataMessage: null,
      addressDataInfo: [
        { field: "city", message: "città", required: true },
        { field: "address1", message: "indirizzo", required: true },
        { field: "postalcode", message: "CAP", required: true },
        { field: "province", message: "provincia", required: true },
        { field: "addressNumber", message: "civico", required: true }
      ],
      // floorItems: [
      //   "Piano terra",
      //   "1",
      //   "2",
      //   "3",
      //   "4",
      //   "5",
      //   "6",
      //   "Oltre il sesto"
      // ],
      selectedProvince: null,
      selectedCity: null,
      selectedAddress: null,
      city: null,
      address1: null
    };
  },
  directives: { mask },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    checkRequiredValues() {
      return (
        this.internalValue.addressName &&
        this.internalValue.doorbellName &&
        this.internalValue.referencePhoneNumber
      );
    },
    enableSave() {
      return !this.valid;
    },
    titleSection() {
      return this.internalValue.addressId
        ? this.$t("profile.addresses.editAddressTitle", [
            this.internalValue.addressName
          ])
        : this.$t("profile.addresses.newAddressTitle");
    }
  },
  methods: {
    ...mapActions({
      // setAddress: "cart/setAddress",
      getCart: "cart/getCart"
    }),
    getAddressData(addressData, place) {
      //check @home
      let vm = this;

      this.missingAddressData = false;

      //provincia
      place.address_components.forEach(element => {
        if (element.types[0] == "administrative_area_level_2") {
          vm.internalValue.province = element.short_name;
          return;
        }
        if (element.types[0] == "country") {
          vm.internalValue.country = { iso: element.short_name };
          return;
        }
      });

      //città
      place.address_components.forEach(element => {
        if (
          element.types[0] == "administrative_area_level_3" ||
          element.types[0] == "locality"
        )
          //add custom variable
          vm.internalValue.city = element.short_name;
        return;
      });

      // vm.internalValue.city =
      //   addressData.administrative_area_level_3 ||
      //   addressData.locality ||
      //   addressData.administrative_area_level_2;
      // if (
      //   !addressData.administrative_area_level_3 &&
      //   !addressData.locality &&
      //   !(vm.internalValue.city === vm.internalValue.cityAdditionalInfo)
      // ) {
      //   vm.internalValue.city =
      //     vm.internalValue.city + " - " + vm.internalValue.cityAdditionalInfo;
      // }

      vm.internalValue.postalcode = addressData.postal_code;
      vm.internalValue.addressNumber = addressData.street_number;
      vm.internalValue.address1 = addressData.route;
      vm.internalValue.latitude = addressData.latitude;
      vm.internalValue.longitude = addressData.longitude;
      vm.internalValue.gpsLatitude = addressData.latitude;
      vm.internalValue.gpsLongitude = addressData.longitude;
      vm.checkAddressDisabled = false;
    },
    async checkAddress() {
      let vm = this;
      vm.missingAddressData = false;
      vm.missingAddressDataMessage =
        "L'indirizzo inserito è incompleto, mancano i seguenti campi: ";
      vm.addressDataInfo.forEach(element => {
        if (element.required) {
          if (vm.internalValue[element.field] == undefined) {
            vm.missingAddressDataMessage += element.message + ", ";
            vm.missingAddressData = true;
          }
        }
      });
      if (vm.missingAddressData) {
        vm.missingAddressDataMessage = vm.missingAddressDataMessage.slice(
          0,
          -2
        );
        vm.missingAddressDataMessage += ".";
      } else {
        try {
          vm.dataValidated = await DeliveryService.isValidAddress({
            country: vm.internalValue.country,
            longitude: vm.internalValue.longitude,
            gpsLongitude: vm.internalValue.longitude,
            latitude: vm.internalValue.latitude,
            gpsLatitude: vm.internalValue.latitude,
            address1: vm.internalValue.address1,
            city: vm.internalValue.city,
            postalcode: vm.internalValue.postalcode,
            province: vm.internalValue.province,
            addressNumber: vm.internalValue.addressNumber
          });
          if (vm.dataValidated.active == 1) {
            vm.homeDeliveryAvailable = true;
            vm.showAutomplete = false;
          } else {
            vm.homeDeliveryAvailable = false;
            vm.showAutomplete = false;
          }
          // if (vm.dataValidated.addressNumber) {
          //   vm.isFilled = true;
          //   vm.isReadOnly = true;
          // } else {
          //   vm.isFilled = false;
          //   vm.isReadOnly = false;
          // }
        } catch (err) {
          vm.homeDeliveryAvailable = false;
        }
        this.results = true;
      }
    },
    resetAddress() {
      this.selectedAddress = null;
      this.addressList = [];
      this.internalValue.address1 = null;
    },
    async getAddress(addressId) {
      this.internalValue = await DeliveryService.getAddresById(addressId);
    },
    async validateAndSaveAddress() {
      let vm = this;
      var dataValidated = await DeliveryService.isValidAddress(
        vm.internalValue
      );
      //? se non e' valido mostro snakcbar errore
      if (dataValidated.active === 0) {
        vm.error = "message.invalidAddress1";
      } else {
        if (
          !vm.internalValue.address1 ||
          !vm.internalValue.addressNumber ||
          !vm.internalValue.postalcode ||
          !vm.internalValue.city
        ) {
          vm.error = "message.invalidAddress1";
        } else {
          const text =
            "E' stato trovato questo indirizzo: <b>" +
            vm.internalValue.address1 +
            " " +
            vm.internalValue.addressNumber +
            ", " +
            vm.internalValue.postalcode +
            " " +
            vm.internalValue.city +
            "</b>. Vuoi salvarlo?";
          const dialogRes = await this.$dialog.confirm({ text });
          // eslint-disable-next-line no-unused-vars
          // _this.$emit("removeAddress", address);
          if (dialogRes) {
            vm.saveAddress();
          }
        }
      }
    },
    async saveAddress() {
      let vm = this;
      console.log("ccc save", vm.$refs.shippingAddr.validate());
      var addressUpdated = await DeliveryService.updateAddress(
        vm.internalValue,
        this.$t("addresses.updatedAddress")
      );
      if (addressUpdated) {
        // await vm.setAddress({
        //   addressType: "home",
        //   addressId: addressUpdated.addressId
        // });
        vm.$emit("save");
        vm.$emit("shippingFormValidation", true);
      }
    },
    back() {
      this.resetForm();
      this.$emit("back");
    }
  },
  watch: {
    address1(val, oldVal) {
      if (val && val.trim().length > 3 && val != oldVal) {
        this.fetchAddressSuggestion(val);
      }
    },
    city(val, oldVal) {
      if (val && val.trim().length > 0 && val != oldVal) {
        this.fetchCityList(val, this.provinceId);
      }
    }
  },
  async created() {
    let vm = this;
    if (vm.$route.params.addressId) {
      this.showAutomplete = false;
      this.homeDeliveryAvailable = true;
      await vm.getAddress(vm.$route.params.addressId);
    }
    await this.getCart();
    const addresses = await storeService.getAddressesByDeliveryService(
      this.cart.shippingAddress.addressTypeId
    );
    const selectedAddress = addresses.filter(
      add => add.addressId === this.cart.shippingAddress.addressId
    );
    this.internalValue = merge(this.internalValue, selectedAddress[0]);
    setTimeout(() => {
      const isValid = this.$refs.shippingAddr.validate();
      this.$emit("shippingFormValidation", isValid);
    }, 2000);
    console.log("bbb add", this.internalValue);

    // vm.fetchDefaultData();
  },
  async mounted() {
    await this.$nextTick();
    setTimeout(() => {
      const isValid = this.$refs.shippingAddr.validate();
      this.$emit("shippingFormValidation", isValid);
    }, 2000);

    console.log("ccc valid", this.valid);
  }
};
</script>
